import React from 'react';
import Img_desktop from "../assets/images/single-img/desctop/childrens.webp";
import Img_mobile from "../assets/images/single-img/mobile/children_faq.webp";
import Img_tablet from "../assets/images/single-img/tablet/childrens.webp";
import volkova_avatar from "../assets/images/single-img/desctop/doctors/volk2_avatar.webp";
import simanskaya_avatar from "../assets/images/single-img/desctop/doctors/sim2_avatar.webp";
import serebryakova_avatar from "../assets/images/single-img/desctop/doctors/se_avatar.webp";

const REMOVE_NEW_QUESTION = "REMOVE_NEW_QUESTION";

const initialState = {   
    pageTopPart: {
        pageName: "faq",
        srcDesktop: Img_desktop,            
        srcMobile: Img_mobile,
        srcTablet: Img_tablet,
        title: "Вопрос-Ответ"       
    }, 

    directions: [
        "Все направления",
        "Диагностика бесплодия",
        "Лечение бесплодия",
        "Гинекология",
        "Гинекологические операции",
        "Услуги для беременных",
        "Урология-андрология",
        "Урологические операции",
        "Травматология и ортопедия",
        "Травматолог-ортопед",
        "Артроскопия коленного сустава",  
        "Ультразвуковые исследования",
        "Ударно-волновая терапия",
        "Функциональная диагностика",
        "Генетические тесты",
        "Терапия",  
        "Эндокринология"    
    ],
    doctors: [
        "Гордеева И. В.",
        "Волкова Т.Е.",
        "Ладягина Е.В.",
        "Сабирова Г.З.",
        "Серебрякова Т.А.",
        "Шабаршина М.С.",
        "Симанская С.Ю.",
        "Лавренюк С.А.",
        "Клемендеев А.П",
        "Салмина Н.В",
        "Кожевникова И.В.",
        "Асланова М.В.",
        "Ильгачев А.Н.",
        "Маланина Е.Н.",
        "Степанова А.В.",
        "Гордеев А.В.",
        "Белый Л.Е.",
        "Коновалова Н.В.",
        "Брыгина Н.А.",
        "Малянкова Л.Ю",        
    ],
    avatars: {
        "Волкова Татьяна Евгеньевна": volkova_avatar,
        "Серебрякова Татьяна Анатольевна": serebryakova_avatar,
        "Симанская Светлана Юрьевна": simanskaya_avatar
    },
    questions: [
        {
            direction: "Лечение бесплодия",
            userName: "Анна",
            question: "Здравствуйте! После подачи документов и анализов на ЭКО по ОМС в течении какого времени вы оглашаете решение?",
            doctorName: "Симанская Светлана Юрьевна",
            answer: "Добрый день. Направление выдает женская консультация. Мы не оформляем на ЭКО по ОМС. Вы сдаете все анализы, предоставляете результаты лечащему врачу в женской консультации, врач крепит ваши документы в регистр, где формируется направление на ЭКО по ОМС. Срок действия направления 3 месяца с даты выдачи.",
            date: "11.10.2024"
        },
        {
            direction: "Лечение бесплодия",
            userName: "Аноним",
            question: "Здравствуйте, была в 2023 году у вас с АМГ-0,82 у вас сказали только эко, дали перечень обследования, только в этом году решились на эко, напишите пожалуйста нужно ли опять на прием к вам ? нужно ли сдавать новое амг и что делать дальше",
            doctorName: "Волкова Татьяна Евгеньевна",
            answer: "Добрый день. Учитывая, что прошел год с момента обращения, АМГ лучше пересдать и с результатом записаться к репродуктологу. Как правило, женщинам с низким уровнем АМГ предлагаются программы с донорским материалом. Саму программу ЭКО есть возможность пройти по полису ОМС. Донорский биоматериал пациенты приобретают за счет собственных средств.",
            date: "25.09.2024"
        },
        {
            direction: "Урологические операции",
            userName: "Алена",
            question: "Здравствуйте! Скажите пожалуйста у вас делают операцию по удалению крайней плоти при фимозе и удаление папочкой грыжи ребенку 8 лет? Чтобы сделать за одну операцию все. Если делают, то сколько стоит и какой врач проводтт? Спасибо",
            doctorName: "Симанская Светлана Юрьевна",
            answer: "Добрый день. К сожалению, мы не проводим операции детям.",
            date: "21.09.2024"
        },
        {
            direction: "Гинекология",
            userName: "Анастасия",
            question: "Добрый день! На какой день цикла сдать анализ на АМГ?",
            doctorName: "Волкова Татьяна Евгеньевна",
            answer: "Добрый день. Данный анализ сдается в любой день, независимо от дмц. Вы можете сдать данный анализ в нашей клинике, в любом филиале, без предварительной записи с 8 до 11 часов. Будем рады видеть Вас в нашем центре.",
            date: "18.09.2024"
        },
        {
            direction: "Гинекология",
            userName: "Оксана",
            question: "Какое максимальное возрастное ограничение для проведения ЭКО в целом и по ОМС?",
            doctorName: "Волкова Татьяна Евгеньевна",
            answer: "Добрый день. Ограничений по возрасту нет. Есть ограничения по медицинским показаниям (например при снижении овариального резерва: уровень АМГ менее 1,2, количество антральных фолликулов менее 5 суммарно в обоих яичниках)",
            date: "21.08.2024"
        },
        {
            direction: "Гинекология",
            userName: "Надежда",
            question: "Добрый вечер. Ваша клиника ведет набор доноров ооцитов?",
            doctorName: "Симанская Светлана Юрьевна",
            answer: "Добрый день. В настоящее время клиника не занимается данным направлением.",
            date: "25.06.2024"
        },
        {
            direction: "Услуги для беременных",
            userName: "Оксана",
            question: "Здравствуйте, назовите пожалуйста точную стоимость ведения беременности, от первичного приёма до родов. Спасибо",
            doctorName: "Серебрякова Татьяна Анатольевна",
            answer: "Добрый день. Стоимость от первичной постановки на учет до родов составляет 63 500 рублей. В данную стоимость не входят дополнительные обследования, которые могут понадобиться при наблюдении и не включенные в стандарт обследования. Оплачивать можно по триместрам. Рады будем помочь)",
            date: "25.06.2024"
        }

    ],
    step: 2,
    newQuestions: [
        {
            userName: "Анна",
            question: "Здравствуйте! Скажите пожалуйста, можно ли у вас пройти УЗИ и консультацию гинеколога (не по беременности,по поводу кист эндометриоидных)И сколько будет стоить. Заранее спасибо.",
            id: 1
        },
        {
            userName: "Анна2",
            question: "Добрый день! Скажите пожалуйста, можно ли у вас пройти УЗИ и консультацию гинеколога (не по беременности,по поводу кист эндометриоидных)И сколько будет стоить. Заранее спасибо.",
            id: 2
        }
    ]
}

// export type avatarsType = {
//     "Волкова Татьяна Евгеньевна": string,
//     "Серебрякова Татьяна Анатольевна": string,
//     "Симанская Светлана Юрьевна": string
// } 

export type initialStateType = typeof initialState;

export const faqReducer = (state = initialState, action: any):initialStateType => {

    switch(action.type) {
        case REMOVE_NEW_QUESTION:
            console.log("state.newQuestions", state.newQuestions);
            return {
                ...state,
                newQuestions: [...state.newQuestions.filter(q => q.id !== action.id)]
            }
        default: 
            return state; 
    }
    
};

export const removeNewQuestionActionCreator = (id:number) => {
    return {
        type: REMOVE_NEW_QUESTION,
        id
    }
}