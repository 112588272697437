import React from 'react';

const UsefulInformation = () => {
    return (
        <div>
           Разводящая страница Полезная информация 
        </div>
    );
};

export default UsefulInformation;